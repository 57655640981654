export interface Dataset {
    name: string
    title: string
    md?: string | (() => Promise<string | typeof import("*.md")>) // 通常是通过异步require引入一个markdown文件。（利用了markdown-loader包，实际上require markdown文件得到的就是一段html字符串，因此理论上，这里填写markdown字符串也是没有任何问题的）
    href?: string
    abstract?: string
    img?: string
    tag?: { content: string, color: string }
}

function state() {
    const datasets: Dataset[] = [
        {
            name: "THU-EHROccIR",
            title: "Event-enhanced High-Resolution Occluded Image Reconstruction Dataset",
            md: () => import(/* webpackChunkName: "THU-EHROccIR" */ '../data/dataset/THU-EHROccIR/dataset.md'),
            img: require("../assets/data/eoccsr.png"),
        },
        {
            name: "THU-DepthQA",
            title: "Depth Quality Assessment dataset",
            md: () => import(/* webpackChunkName: "THU-DepthQA" */ '../data/dataset/THU-DepthQA/数据集介绍.md'),
            img: require("../assets/data/depthqa.png"),
        },
        {
            name: "THU-HSEVI",
            title: "THU-HSEVI: High-Speed Event-Enhanced Video Frame Interpolation Dataset",
            md: () => import(/* webpackChunkName: "THU-HSEVI" */ '../data/dataset/THU-HSEVI/HSEVI.md'),
            img: require("../assets/data/hsevi.png"),
        },
        {
            name: "THU-EOccDE",
            title: "Event-enhanced Occluded scene Depth Estimation dataset",
            md: () => import(/* webpackChunkName: "THU-EOccDE" */ '../data/dataset/THU-EOccDE/dataset.md')
        },
        {
            name: "THU-EACT-50",
            title: "THU-EACT-50: A Real-World Event-Based Action Recognition Benchmark",
            md: () => import(/* webpackChunkName: "THU-EACT-50" */ '../data/dataset/THU-EACT-50/README.md')
        },
        {
            name: "Open-Set 3D Object Retrieval",
            title: "OS-MN40, OS-MN40-Miss, OS-MN40-core, OS-NTU-core, OS-ESB-core",
            href: "/os3dor",
            img: require("../assets/data/os3dor.jpg")
        }
    ]
    const datasets_zh: Dataset[] = [
        {
            name: "THU-EHROccIR",
            title: "事件增强遮挡高分辨率图像重建数据集",
            md: () => import(/* webpackChunkName: "THU-EOccSR" */ '../data/dataset/THU-EHROccIR/dataset.md'),
            img: require("../assets/data/eoccsr.png"),
        },
        {
            name: "THU-DepthQA",
            title: "深度质量评估数据集",
            md: () => import(/* webpackChunkName: "THU-DepthQA" */ '../data/dataset/THU-DepthQA/数据集介绍.md'),
            img: require("../assets/data/depthqa.png"),
        },
        {
            name: "THU-HSEVI",
            title: "高速事件增强视频帧插值数据集",
            md: () => import(/* webpackChunkName: "THU-HSEVI" */ '../data/dataset/THU-HSEVI/HSEVI.md'),
            img: require("../assets/data/hsevi.png"),
        },
        {
            name: "THU-EOccDE",
            title: "事件增强遮挡场景深度估计数据集",
            md: () => import(/* webpackChunkName: "THU-EOccDE" */ '../data/dataset/THU-EOccDE/dataset.md')
        },
        {
            name: "THU-EACT-50",
            title: "THU-EACT-50: 基于事件数据的动作识别数据集",
            md: () => import(/* webpackChunkName: "THU-EACT-50" */ '../data/dataset/THU-EACT-50/README.md')
        },
        {
            name: "开放场景下的立体对象检索",
            title: "OS-MN40, OS-MN40-Miss, OS-MN40-core, OS-NTU-core, OS-ESB-core",
            href: "/os3dor",
            img: require("../assets/data/os3dor.jpg")
        }
    ]
    return { zh: datasets_zh, en: datasets }
}

export default state()
